.ant-upload-list-item-actions {
    display: none !important;
}

/* YourComponent.css */
.image-item {
    position: relative;
    display: inline-block;
}

.image-item img {
    display: block;
    width: 100%;
}

.delete-button {
    position: absolute;
    top: 5px;
    right: 5px;
    background: rgba(255, 255, 255, 0.7);
    border: none;
    border-radius: 50%;
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.delete-button:hover {
    background: rgba(255, 0, 0, 0.7);
    color: white;
}
